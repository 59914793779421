import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SmallHeroSection from "../components/SmallHeroSection"
import DecorativeSection from "../components/DecorativeSection"
import { graphql } from "gatsby"
import EventSection from "../components/home/EventSection"
import PriceData from "../assets/js/priceData"

export const query = graphql`
  {
    file(name: { eq: "logo-koziol" }) {
      publicURL
    }
  }
`

const PricePage = ({ data }) => {
  return (
    <Layout page="cennik">
      <Seo title="Cennik" />
      <SmallHeroSection />
      <DecorativeSection />

      <section id="price" class="price">
        <div class="section-title">
          <h2>
            <span>Cennik</span>
          </h2>
          <p class="px-3">
            Istnieje możliwość <strong>indywidualnej</strong> modyfikacji
            kaloryczności posiłków! Ponadto gwarantujemy{" "}
            <strong>różnorodność</strong> posiłków – codziennie otrzymają
            Państwo inny zestaw posiłków!
          </p>
        </div>
      </section>

      <section id="price-list">
        <div class="container">
          {PriceData.map((item, index2) => (
            <>
              <p class="price-5-meals">{item.mealText}</p>

              {item.diets.map((diet, index) => (
                <div key={index}>
                  <p class="diet-title"> {diet.shortTitle}</p>

                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Kalorie</th>
                        <th scope="col">1-13 dni</th>
                        <th scope="col">14-20 dni</th>
                        <th scope="col">21-27 dni</th>
                        <th scope="col">{">28 dni"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {diet.prices.map((price, prceIndex) => (
                        <tr key={prceIndex}>
                          <th scope="row">{price.option}</th>
                          {price.days.map((day, dayIndex) => (
                            <td key={dayIndex}>{day+7} zł</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </>
          ))}
        </div>

        <div class="container">
          <p class="price-list-text">
            ZAMÓW ZESTAW <span>MIESIĘCZNY</span> I OTRZYMAJ WIZYTĘ I KONSULTACJE
            DIETETYCZNE <span>GRATIS</span> !
          </p>
        </div>

        {/* <div class="container logo-box">
          <a href="https://zdrowykoziol.com/" target="_blank">
            <img
              class="logo-koziol"
              src={data.file.publicURL}
              alt="Zdrowy Kozioł"
            ></img>
          </a>
        </div> */}
      </section>

      <EventSection title="Zamów zestaw testowy " subTitle="już od 29.99 zł" />

      <section id="sales" class="sales">
        <div class="section-title">
          <h2>
            <span>Razem</span> taniej !
          </h2>
          <p>
            Kilka zamówień na <strong>jeden adres</strong>? To się opłaca! Namów
            rodzinę lub przyjaciół na wspólną dietę i skorzystajcie z{" "}
            <strong>rabatu</strong> na każde zamówienie.*
          </p>
        </div>

        {/* <p class="toogether-text">Razem taniej !</p>
        <p class="text-center">Kilka zamówień na ten sam adres? To się opłaca! Namów rodzinę lub przyjaciół na wspólną dietę i skorzystajcie z rabatu na każde zamówienie.</p> */}

        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <div class="box-sale">
                <div class="percent-box percent-box-1">
                  <span
                    class="percent-number"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    2%
                  </span>
                </div>
                <p>
                  Zniżka przy zamówieniu<strong> dwóch </strong>pakietów na
                  jeden adres.
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="box-sale">
                <div class="percent-box percent-box-2">
                  <span
                    class="percent-number"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    4%
                  </span>
                </div>
                <p>
                  Zniżka przy zamówieniu<strong> czterech </strong>pakietów na
                  jeden adres.
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="box-sale">
                <div class="percent-box percent-box-3">
                  <span
                    class="percent-number"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    6%
                  </span>
                </div>
                <p>
                  Zniżka przy zamówieniu<strong> sześciu </strong>pakietów na
                  jeden adres.
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="box-sale">
                <div class="percent-box percent-box-4">
                  <span
                    class="percent-number"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    10%
                  </span>
                </div>
                <p>
                  Zniżka przy zamówieniu<strong> dziesięciu i więcej </strong>
                  pakietów na jeden adres.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="section-title">
          <p>
            *oferta obowiązuje tylko dla zamówień na 5 posiłków
          </p>
        </div>



      </section>
    </Layout>
  )
}

export default PricePage
